import { auth, db } from "../firebase";
import firebase from "firebase";

export class UserApi {
  static loginUserWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    return auth().signInWithPopup(provider);
  }

  static loginUserWithTwitter() {
    const provider = new auth.TwitterAuthProvider();
    // provider.addScope("profile");
    // provider.addScope("email");
    return auth().signInWithPopup(provider);
  }
  

  static logoutUser() {
    return auth().signOut();
  }

  static async saveUser(currentUser) {
    const docRef = db.collection("users").doc(currentUser.id);
    let o = {};
    const user = await docRef.get();
    if (user.exists) {
      //user is already there, write only last login
      o.lastLoginDate = firebase.firestore.Timestamp.fromDate(new Date());
      await docRef.update(o);
      const user = await docRef.get();
      return { ...user.data(), id: user.id };
    } else {
      //new user
      o.id = currentUser.id;
      o.name = currentUser.name;
      o.avatar = currentUser.avatar;
      o.email = currentUser.email;
      o.role = 3; // Audience (Default Role For Every User [1-Moderator,2-Speaker,3-Audience])
      o.accountCreatedDate = firebase.firestore.Timestamp.fromDate(new Date());
      o.lastLoginDate = firebase.firestore.Timestamp.fromDate(new Date());
      // Set it
      docRef.set(o);
      const user = await docRef.get();
      return { ...user.data(), id: user.id };
    }
  }

  static addUserToRoom(data, room_id) {
    return db
      .collection("rooms")
      .doc(room_id)
      .collection("users")
      .doc(data.id)
      .set(data);
  }
}
