import React, { useState, useEffect } from "react";

import {
  f7ready,
  App,
  Panel,
  View,
  Popup,
  Page,
  Navbar,
  NavRight,
  Link,
  Block,
} from "framework7-react";

import routes from "../js/routes";
import store from "../js/store";

import { Provider } from "react-redux";
import Store from "../redux/reducers";

const MyApp = () => {
  // Framework7 Parameters
  const f7params = {
    name: "Sanket App", // App name
    theme: "auto", // Automatic theme detection
    view: {
      browserHistory: true,
    },
    // App store
    store: store,
    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: {
      path: "/service-worker.js",
    },
  };

  f7ready(() => {
    // Call F7 APIs here
  });

  return (
    <Provider store={Store}>
      <App {...f7params}>
        {/* Left panel with cover effect*/}
        <Panel left cover themeDark>
          <View>
            <Page>
              <Navbar title="Left Panel" />
              <Block>Left panel content goes here</Block>
            </Page>
          </View>
        </Panel>

        {/* Right panel with reveal effect*/}
        <Panel right reveal themeDark>
          <View>
            <Page>
              <Navbar title="Right Panel" />
              <Block>Right panel content goes here</Block>
            </Page>
          </View>
        </Panel>

        {/* Your main view, should have "view-main" class */}
        <View main className="safe-areas" url="/" />

        {/* Popup */}
        <Popup id="my-popup">
          <View>
            <Page>
              <Navbar title="Popup">
                <NavRight>
                  <Link popupClose>Close</Link>
                </NavRight>
              </Navbar>
              <Block>
                <p>Popup content goes here.</p>
              </Block>
            </Page>
          </View>
        </Popup>

        {/* Agora - My own audio stream */}
        <div id="me"></div>

        {/* Agora - Others audio streams except mine */}
        <div id="remote-container"></div>
      </App>
    </Provider>
  );
};
export default MyApp;
