import {
  Button,
  Link,
  List,
  ListInput,
  ListItem,
  Navbar,
  NavRight,
  Page,
  Popup,
} from "framework7-react";
import React from "react";

export default function AddRoomPopup({ setState, addRoom, addRoomPopupOpen }) {
  return (
    <Popup
      className="add-room-popup"
      swipeToClose
      opened={addRoomPopupOpen}
      onPopupClosed={() => setState({ addRoomPopupOpen: false })}
    >
      <Page>
        <Navbar title="Add New Room">
          <NavRight>
            <Link popupClose>Close</Link>
          </NavRight>
        </Navbar>
        <form id="add-room-form" onSubmit={addRoom}>
          <List inset>
            <ListInput
              type="text"
              placeholder="Enter Room Name"
              label="Room Name"
              outline
              clearButton
              name="roomName"
            />
            <ListInput
              type="textarea"
              placeholder="Enter Room Description"
              label="Room Description"
              outline
              clearButton
              name="roomDescription"
            />
            <ListItem>
              <Button type="submit" text="Add" fill />
            </ListItem>
          </List>
        </form>
      </Page>
    </Popup>
  );
}
