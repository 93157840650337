const initialState = {
  user: null,
  loggedIn: false,
};


export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "User Login": {
      return { ...state, loggedIn: true };
    }
    case "User Logout": {
      return { ...state, loggedIn: false };
    }
    case "Set User": {
      return { ...state, user: action.payload };
    }
    default: {
      return state;
    }
  }
};
