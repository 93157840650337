import { auth, db } from "../firebase";

export class RoomApi {
  static fetchRooms() {
    return db.collection("rooms").orderBy("createdDate", "desc").get();
  }

  static fetchRoom(id) {
    return db.collection("rooms").doc(id).get();
  }

  static deleteRoom(id) {
    return db.collection("rooms").doc(id).delete();
  }

  static addRoom(data) {
    return db.collection("rooms").doc().set(data);
  }

  static changeRoomMemberRole(roomId, userId, role) {
    return db
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .doc(userId)
      .update({ role: role });
  }

  static toggleMuteFlag(roomId, roomUserDetails) {
    return db
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .doc(roomUserDetails.id)
      .update({ muteFlag: !roomUserDetails.muteFlag });
  }

  static toggleRaiseHandFlag(roomId, roomUserDetails) {
    return db
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .doc(roomUserDetails.id)
      .update({ raiseHandFlag: !roomUserDetails.raiseHandFlag });
  }

  static leaveRoom(roomId, roomUserDetails) {
    return db
      .collection("rooms")
      .doc(roomId)
      .collection("users")
      .doc(roomUserDetails.id)
      .delete();
  }
}
