import {
  Navbar,
  NavTitle,
  Page,
  f7,
  Fab,
  Icon,
  NavRight,
  Button,
  Link,
} from "framework7-react";
import React, { Component } from "react";
import { UserApiRepository } from "../services/user/UserApiRepository";
import { connect } from "react-redux";
import { auth, db } from "../services/firebase";
import firebase from "firebase";
import { RoomApiRepository } from "../services/room/RoomApiRepository";
import MemberCard from "../components/MemberCard";
import MemberPopover from "../components/MemberPopover";
import { getEnvVariable } from "../environment";
import Agora from "../services/agora";

class RoomPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: props.rooms,
      tokenData:'ok Google',
      selectedRoomId: this.props.roomId,
      selectedMember: null,
    };
  }

  componentDidMount = async () => {
    const app = f7;

    // Real time monitoring of the session
    auth().onAuthStateChanged(async (user) => {
      app.dialog.preloader("Fetching User");
      if (user) {
         // console.log(this.props.rooms.length);
        // User is Logged In
        await this.props.setUser(user);
        if (!this.props.joinedFlag) {
          this.joinRoom();
        }

        app.dialog.close();
      } else {
        // User is Logged Out
        await this.props.setUser(null);
        app.dialog.close();
      }
    });
  };

  // User Login
  loginUser = async (provider) => {
    await this.props.loginUser(provider);
  };

  // User Logout
  logoutUser = async () => {
    await this.props.logoutUser();
    //this.props.f7router.navigate('/');
  };

  // Join Room
  joinRoom = async () => {
    const roomDetails = await this.props.fetchRoom(this.state.selectedRoomId);
    const userDetails = this.props.user;
    await this.props.joinRoom(userDetails, roomDetails);
    this.joinAgora(userDetails, roomDetails);
    this.getJoinedRoom();
    this.getJoinedUser();
    this.getJoinedModerators();
    this.getJoinedSpeakers();
    this.getJoinedAudience();
  };

  // Get/Set realtime data of currently joined room
  getJoinedRoom = () => {
    try {
      db.collection("rooms")
        .doc(this.state.selectedRoomId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            this.props.setJoinedRoom(snapshot.data());
          }
        });
    } catch (error) {}
  };

  // Get/Set realtime data of currently joined user
  getJoinedUser = () => {
    try {
      db.collection("rooms")
        .doc(this.state.selectedRoomId)
        .collection("users")
        .doc(this.props.user.id)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            this.props.setJoinedUser(snapshot.data());
            if (this.props.agoraInstance) {
              if (snapshot.data().role != 3) {
                // If user is of type moderator/speaker then publish stream
                this.publishLocalSteam();
              } else {
                // If user is of type audience then unpublish stream
                this.unpublishLocalSteam();
              }
              if (snapshot.data().muteFlag) {
                this.muteLocalSteam();
              } else {
                this.unmuteLocalSteam();
              }
            }
          }
        });
    } catch (error) {}
  };

  // Get/Set realtime data of currently joined moderators
  getJoinedModerators = () => {
    try {
      db.collection("rooms")
        .doc(this.state.selectedRoomId)
        .collection("users")
        .where("role", "==", 1)
        .onSnapshot((snapshot) => {
          this.props.setJoinedModerators(snapshot);
        });
    } catch (error) {}
  };

  // Get/Set realtime data of currently joined speakers
  getJoinedSpeakers = () => {
    try {
      db.collection("rooms")
        .doc(this.state.selectedRoomId)
        .collection("users")
        .where("role", "==", 2)
        .onSnapshot((snapshot) => {
          this.props.setJoinedSpeakers(snapshot);
        });
    } catch (error) {}
  };

  // Get/Set realtime data of currently joined audience
  getJoinedAudience = () => {
    try {
      db.collection("rooms")
        .doc(this.state.selectedRoomId)
        .collection("users")
        .where("role", "==", 3)
        .onSnapshot((snapshot) => {
          this.props.setJoinedAudience(snapshot);
        });
    } catch (error) {}
  };

  toggleMute = () => {
    try {
      this.props.toggleMuteFlag(
        this.state.selectedRoomId,
        this.props.joinedUser
      );
    } catch (error) {}
  };

  toggleRaiseHandFlag = () => {
    try {
      this.props.toggleRaiseHandFlag(
        this.state.selectedRoomId,
        this.props.joinedUser
      );
    } catch (error) {}
  };

  // Member Item Click Handler
  memberClickHandler = (member) => {
    console.log(member);
    this.setState({
      selectedMember: member,
    });
  };

  // Change User Type/Role
  changeSelectedUserType = async (type) => {
    try {
      await this.props.changeRoomMemberRole(
        this.state.selectedRoomId,
        this.state.selectedMember.id,
        type
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Leave Room
  leaveRoom = async () => {
    try {
      f7.dialog.preloader("Leaving Room");
      console.log('Loging');
    //  this.unpublishLocalSteam();
      
      this.leave();
      await this.props.leaveRoom(this.props.roomId, this.props.joinedUser);
      f7.dialog.close();
      this.props.f7router.navigate("/user/");
       
    } catch (error) {}
  };



 



  // Agora Methods ------------------------------------------------------------- [START]
  joinAgora = async (userDetails, roomDetails) => {
    if (userDetails) {
         try{
          if(this.state.rooms.length>0){
            for(let i=0;i<this.state.rooms.length;i++){
              if(this.state.rooms[i].roomName==roomDetails.roomName){
               this.setState({tokenData:this.state.rooms[i].cretedToken});
             //  console.log(this.state.tokenData);
              // console.log("Yes ",this.state.rooms[i].roomName)
              }
            }
          }
         
         }catch (error) {
          console.log(error);
        }

      let config = {
        APP_ID: getEnvVariable().AGORA_APP_ID,
       //TOKEN: getEnvVariable().AGORA_TOKEN,
        TOKEN:this.state.tokenData,
        CHANNEL_NAME: roomDetails.roomName,
        MODE: getEnvVariable().AGORA_MODE,
        CODEC: getEnvVariable().AGORA_CODEC,
        USER_ID: userDetails.id,
      };
      let agoraInstance = new Agora(config);
      if (agoraInstance.config) {
        agoraInstance.getConfig();
        await agoraInstance.createClient();
        let client = await agoraInstance.initClient();
        let localStream = await agoraInstance.join();
        await agoraInstance.initLocalSteam();
        await this.props.setAgoraInstance(agoraInstance);
        if (userDetails.muteFlag) {
          await agoraInstance.muteLocalSteam();
        }
        console.log(config);
        console.log(client);
        console.log(localStream);
      }
    }
  };
  publishLocalSteam = async () => {
    await this.props.agoraInstance.publishLocalSteam();
  };
  unpublishLocalSteam = async () => {
    await this.props.agoraInstance.unpublishLocalSteam();
  };
  muteLocalSteam = async () => {
    await this.props.agoraInstance.muteLocalSteam();
  };
  unmuteLocalSteam = async () => {
    await this.props.agoraInstance.unmuteLocalSteam();
  };
  leave = async () => {
    await this.props.agoraInstance.leave();
  };
  // Agora Methods ------------------------------------------------------------- [END]

  render() {
    const { rooms, vlData } = this.state;
   
    const {
      user,
      joinedFlag,
      joinedRoom,
      joinedUser,
      joinedModerators,
      joinedSpeakers,
      joinedAudience,
    } = this.props;
    return (
      <Page>
        <Navbar backLink>
          <NavTitle>Room Page</NavTitle>
          <NavRight>
            <Link icononly iconF7={"arrowshape_turn_up_right"} href={`https://api.whatsapp.com/send?text=${encodeURIComponent("Join me on SanketApp conference on the link " + location.href)}`} target="_blank" external></Link>
            {!user && (
              <Button small iconOnly iconF7={"logo_google"} onClick={() => this.loginUser("google")} />
            )}
            {!user && (
              <Button small iconOnly iconF7={"logo_twitter"} onClick={() => this.loginUser("twitter")} />
            )}
            {!!user && (
              <Button outline small text="Logout" onClick={this.logoutUser} />
            )}
          </NavRight>
        </Navbar>
        {joinedFlag && joinedUser && (
          <Fab
            position="left-bottom"
            slot="fixed"
            color="red"
            text={"Leave"}
            onClick={this.leaveRoom}
          >
            <Icon f7={"multiply"} />
          </Fab>
        )}
        {joinedFlag && joinedUser && joinedUser.role !== 3 && (
          <Fab
            position="center-bottom"
            slot="fixed"
            text={joinedUser.muteFlag ? "Unmute" : "Mute"}
            color="red"
            onClick={this.toggleMute}
          >
            <Icon f7={joinedUser.muteFlag ? "mic_slash_fill" : "mic_fill"} />
          </Fab>
        )}
        {joinedFlag && joinedUser && joinedUser.role == 3 && (
          <Fab
            position="center-bottom"
            slot="fixed"
            text={joinedUser.raiseHandFlag ? "Cancel" : "Raise Hand"}
            color="red"
            onClick={this.toggleRaiseHandFlag}
          >
            <Icon
              f7={
                joinedUser.raiseHandFlag ? "hand_raised_slash" : "hand_raised"
              }
            />
          </Fab>
        )}
        {joinedFlag && (
          <>
            <MemberCard
              title={"Moderators"}
              data={joinedModerators}
              memberClickHandler={this.memberClickHandler}
            />
            <MemberCard
              title={"Speakers"}
              data={joinedSpeakers}
              memberClickHandler={this.memberClickHandler}
            />
            <MemberCard
              title={"Audience"}
              data={joinedAudience}
              memberClickHandler={this.memberClickHandler}
            />
            <MemberPopover
              selectedUser={this.state.selectedMember}
              changeUserType={this.changeSelectedUserType}
            />
          </>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  rooms: state.roomReducer.rooms,
  joinedFlag: state.roomReducer.joinedFlag,
  joinedRoom: state.roomReducer.joinedRoom,
  joinedUser: state.roomReducer.joinedUser,
  joinedModerators: state.roomReducer.joinedModerators,
  joinedSpeakers: state.roomReducer.joinedSpeakers,
  joinedAudience: state.roomReducer.joinedAudience,
  agoraInstance: state.roomReducer.agoraInstance,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (provider) => dispatch(UserApiRepository.loginUser(provider)),
  logoutUser: () => dispatch(UserApiRepository.logoutUser()),
  setUser: (data) => dispatch(UserApiRepository.setUser(data)),
  fetchRoom: (id) => dispatch(RoomApiRepository.fetchRoom(id)),
  joinRoom: (userData, roomData) =>
    dispatch(UserApiRepository.joinRoom(userData, roomData)),
  setJoinedRoom: (data) => dispatch(RoomApiRepository.setJoinedRoom(data)),
  setJoinedUser: (data) => dispatch(RoomApiRepository.setJoinedUser(data)),
  setJoinedModerators: (data) =>
    dispatch(RoomApiRepository.setJoinedModerators(data)),
  setJoinedSpeakers: (data) =>
    dispatch(RoomApiRepository.setJoinedSpeakers(data)),
  setJoinedAudience: (data) =>
    dispatch(RoomApiRepository.setJoinedAudience(data)),
  toggleMuteFlag: (roomId, roomUserDetails) =>
    dispatch(RoomApiRepository.toggleMuteFlag(roomId, roomUserDetails)),
  toggleRaiseHandFlag: (roomId, roomUserDetails) =>
    dispatch(RoomApiRepository.toggleRaiseHandFlag(roomId, roomUserDetails)),
  changeRoomMemberRole: (roomId, userId, role) =>
    dispatch(RoomApiRepository.changeRoomMemberRole(roomId, userId, role)),
  leaveRoom: (roomId, roomUserDetails) =>
    dispatch(RoomApiRepository.leaveRoom(roomId, roomUserDetails)),
  setAgoraInstance: (agoraInstance) =>
    dispatch(RoomApiRepository.setAgoraInstance(agoraInstance)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomPage);
