import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { RoomReducer } from "./RoomReducer";
import { UserReducer } from "./UserReducer";

export const rootReducer = combineReducers({
  userReducer: UserReducer,
  roomReducer: RoomReducer,
});

const composeEnhancers = compose;

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
