import { RoomActions } from "../../redux/actions/RoomActions";
import { RoomApi } from "./RoomApi";

export class RoomApiRepository {
  static fetchRooms() {
    return async (dispatch) => {
      try {
        const data = await RoomApi.fetchRooms();

        if (data && data.size > 0) {
          let rooms = [];
          data.docs.forEach((v) => {
            const room = v.data();
            room["id"] = v.id;
            rooms.push(room);
            if (data.size === rooms.length) {
              dispatch(RoomActions.fetchRoomsAction(rooms));
            }
          });
        }
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static addRoom(data) {
    return async (dispatch) => {
      try {
        const response = RoomApi.addRoom(data);
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static fetchRoom(id) {
    return async (dispatch) => {
      try {
        const response = await RoomApi.fetchRoom(id);
        return { ...response.data(), id: response.id };
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static deleteRoom(id) {
    return async (dispatch) => {
      try {
        const response = await RoomApi.deleteRoom(id);
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static setJoinedRoom(data) {
    return async (dispatch) => {
      try {
        dispatch(RoomActions.setJoinedRoomAction(data));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static setJoinedUser(data) {
    return async (dispatch) => {
      try {
        dispatch(RoomActions.setJoinedUserAction(data));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static setJoinedModerators(data) {
    return async (dispatch) => {
      try {
        if (data && data.size > 0) {
          let members = [];
          data.docs.forEach((v) => {
            const member = v.data();
            // member["id"] = v.id;
            members.push(member);
            if (data.size === members.length) {
              dispatch(RoomActions.setJoinedModeratorsAction(members));
              return Promise.resolve();
            }
          });
        } else {
          dispatch(RoomActions.setJoinedModeratorsAction([]));
          return Promise.resolve();
        }
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static setJoinedSpeakers(data) {
    return async (dispatch) => {
      try {
        if (data && data.size > 0) {
          let members = [];
          data.docs.forEach((v) => {
            const member = v.data();
            // member["id"] = v.id;
            members.push(member);
            if (data.size === members.length) {
              dispatch(RoomActions.setJoinedSpeakersAction(members));
              return Promise.resolve();
            }
          });
        } else {
          dispatch(RoomActions.setJoinedSpeakersAction([]));
          return Promise.resolve();
        }
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static setJoinedAudience(data) {
    return async (dispatch) => {
      try {
        if (data && data.size > 0) {
          let members = [];
          data.docs.forEach((v) => {
            const member = v.data();
            // member["id"] = v.id;
            members.push(member);
            if (data.size === members.length) {
              dispatch(RoomActions.setJoinedAudienceAction(members));
              return Promise.resolve();
            }
          });
        } else {
          dispatch(RoomActions.setJoinedAudienceAction([]));
          return Promise.resolve();
        }
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static toggleMuteFlag(roomId, roomUserDetails) {
    return async (dispatch) => {
      try {
        await RoomApi.toggleMuteFlag(roomId, roomUserDetails);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static toggleRaiseHandFlag(roomId, roomUserDetails) {
    return async (dispatch) => {
      try {
        await RoomApi.toggleRaiseHandFlag(roomId, roomUserDetails);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static changeRoomMemberRole(roomId, userId, role) {
    return async (dispatch) => {
      try {
        await RoomApi.changeRoomMemberRole(roomId, userId, role);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static setAgoraInstance(agoraInstance) {
    return async (dispatch) => {
      try {
        dispatch(RoomActions.setAgoraInstanceAction(agoraInstance));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static leaveRoom(roomId, roomUserDetails) {
    return async (dispatch) => {
      try {
        await RoomApi.leaveRoom(roomId, roomUserDetails);
        dispatch(RoomActions.leaveRoomAction());
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }
}
