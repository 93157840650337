import firebase from "firebase";
import "firebase/auth";
import { getEnvVariable } from "../environment";

const config = {
  apiKey: getEnvVariable().FIREBASE_API_KEY,
  authDomain: getEnvVariable().FIREBASE_AUTH_DOMAIN,
  projectId: getEnvVariable().FIREBASE_PROJECT_ID,
  storageBucket: getEnvVariable().FIREBASE_STORAGE_BUCKET,
  messagingSenderId: getEnvVariable().FIREBASE_MESSAGING_SENDER_ID,
  appId: getEnvVariable().FIREBASE_APP_ID,
};

const app = firebase.initializeApp(config);

export const auth = firebase.auth;
export const db = firebase.firestore();

export default app;
