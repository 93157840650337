import {
    Link,
    Page,
    f7,
    Button,
    Block,
    Row,
    Icon,
  } from "framework7-react";
  import React, { Component} from "react";
  import { UserApiRepository } from "../services/user/UserApiRepository";
  import { connect } from "react-redux";
  import { auth } from "../services/firebase";
  import { RoomApiRepository } from "../services/room/RoomApiRepository";

  
  class Signup extends Component {
    constructor(props) {
      super(props);
      this.state = {
        rooms: props.rooms,
        vlData: {
          items: [],
        },
        addRoomPopupOpen: false,
        selectedRoomId: "",
      };
    }
    
  
    componentDidMount = async () => {
      const app = f7;
      auth().onAuthStateChanged(async (user) => {
        app.dialog.preloader("Fetching User");
        if (user) { 
          await this.props.setUser(user);
          app.dialog.close();  
          this.props.f7router.navigate('/user/'); 
        } else {
          // User is Logged Out
          await this.props.setUser(null);
          app.dialog.close();
        }
      });
    };
    
    // User Login
    loginUser = async (provider) => {
      await this.props.loginUser(provider);
    };
  
    // User Logout
    logoutUser = async () => {
      await this.props.logoutUser();
    };
  
    render() {
      const {user} = this.props;
      return (
        <Page   style={{backgroundColor:'#cccccc',textAlign:'center'}}>
        
          {!user&&(
            <>
            <div style={{marginTop:'10%'}}>
              <h1>Sign in Here :- </h1>
            </div>

          <div style={{marginTop:'15%'}}>
          <div style={{width:'75%',marginLeft:'12.5%',marginRight:'12.5%',marginBottom:'10%'}}>
     
          <Button  small iconOnly iconF7={"logo_google"}  onClick={() => this.loginUser("google")} iconSize={30} style={{backgroundColor:"white",color:'black',paddingTop:20,paddingBottom:20}}>Sign in with Google</Button>
          </div>
          <div style={{width:'75%',marginLeft:'12.5%',marginRight:'12.5%',marginBottom:'10%'}}>
          <Button  small iconOnly iconF7={"logo_twitter"}  onClick={() => this.loginUser("twitter")} iconSize={30} style={{backgroundColor:"#33acdd",color:'white',paddingTop:20,paddingBottom:20}}>Sign in with Twitter</Button>
          </div>
          {/**
          <div style={{width:'75%',marginLeft:'12.5%',marginRight:'12.5%',marginBottom:'10%'}}>
          <Button  small iconOnly iconF7={"logo_facebook"}  onClick={() => this.loginUser("google")} iconSize={30} style={{backgroundColor:"blue",color:'white',paddingTop:20,paddingBottom:20}}>Sign in with Facebook</Button>
          </div>
         
          <div style={{width:'75%',marginLeft:'12.5%',marginRight:'12.5%',marginBottom:'10%'}}>
          <Button  small iconOnly iconF7={"phone"}  onClick={() => this.loginUser("google")} iconSize={30} style={{backgroundColor:"green",color:'white',paddingTop:20,paddingBottom:20}}> Sign in with Phone</Button>
          </div>
          <div style={{width:'75%',marginLeft:'12.5%',marginRight:'12.5%',marginBottom:'10%'}}>
          <Button  small iconOnly iconF7={"envelope"}  onClick={() => this.loginUser("google")} iconSize={30} style={{backgroundColor:"red",color:'white',paddingTop:20,paddingBottom:20}}> SignIn with Google</Button>
          </div>
           */}
          </div>
          </>
          )}
          {/**
          {!!user && (
            <Block>
              <Block style={{fontSize:20,textAlign:'center',paddingTop:'30%',backgroundColor:'#cccccc'}}>
                <h1>You are loged IN.</h1>
            </Block>  
            <Block  style={{marginTop:'90%'}}>
              <Button style={{backgroundColor:'#39A2DB',padding:20,width:'50%',margin:10,borderRadius:50,marginLeft:'25%',marginRight:'25%',marginBottom:'50%',}}>
                <Link style={{color:'white'}} href="/user/">Tap To Continue</Link>
              </Button>
            </Block> 
            </Block> 
            
             
            )}  */}
        </Page>
      );
    }
  }
  
  const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    rooms: state.roomReducer.rooms,
    joinedFlag: state.roomReducer.joinedFlag,
    joinedRoom: state.roomReducer.joinedRoom,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(UserApiRepository.setUser(data)),
    loginUser: (provider) => dispatch(UserApiRepository.loginUser(provider)),
    logoutUser: () => dispatch(UserApiRepository.logoutUser()),
    fetchRooms: () => dispatch(RoomApiRepository.fetchRooms()),
    addRoom: (data) => dispatch(RoomApiRepository.addRoom(data)),
    fetchRoom: (id) => dispatch(RoomApiRepository.fetchRoom(id)),
    deleteRoom: (id) => dispatch(RoomApiRepository.deleteRoom(id)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Signup);
  