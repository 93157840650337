import { UserActions } from "../../redux/actions/UserActions";
import { UserApi } from "./UserApi";
import firebase from "firebase";


export class UserApiRepository {
  static setUser(data) {
    return async (dispatch) => {
      try {
        if (data) {
          const { uid, displayName, photoURL, email } = data;
          const userData = {
            id: uid,
            name: displayName,
            avatar: photoURL,
            email: email,
          };
          const user = await UserApi.saveUser(userData);
          dispatch(UserActions.setUserAction(user));
        } else {
          dispatch(UserActions.setUserAction(data));
        }
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static loginUser(provider) {
    return async (dispatch) => {
      try {
        if (provider === "google") {
          const data = await UserApi.loginUserWithGoogle();
          if (data && typeof data.user !== "undefined") {
            dispatch(UserActions.userLoginAction());
          }
          return data;
        } else if (provider === "twitter") {
          const data = await UserApi.loginUserWithTwitter();
          if (data && typeof data.user !== "undefined") {
            dispatch(UserActions.userLoginAction());
          }
          return data;
        }                
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static logoutUser() {
    return async (dispatch) => {
      try {
        await UserApi.logoutUser();
        dispatch(UserActions.userLogoutAction());
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }

  static joinRoom(userData, roomData) {
    return async (dispatch) => {
      try {
        const { id, name, email, role, avatar } = userData;
        const roomUser = {
          id: id,
          name: name,
          email: email,
          role: role,
          avatar: avatar,
          muteFlag: true,
          raiseHandFlag: false,
          joinedDate: firebase.firestore.Timestamp.fromDate(new Date()),
        };
        const response = await UserApi.addUserToRoom(roomUser, roomData.id);
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    };
  }
}
