import {
  Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
} from "framework7-react";
import React from "react";

export default function RoomActions({ joinRoom, deleteRoom }) {
  return (
    <Actions id="actions-two-groups">
      <ActionsGroup>
        <ActionsLabel>Room Actions</ActionsLabel>
        <ActionsButton bold onClick={joinRoom}>
          Join Room
        </ActionsButton>
        <ActionsButton color="red" onClick={deleteRoom}>
          Delete Room
        </ActionsButton>
      </ActionsGroup>
      <ActionsGroup>
        <ActionsButton color="red">Cancel</ActionsButton>
      </ActionsGroup>
    </Actions>
  );
}
