export class UserActions {
  static userLoginAction = (data) => ({
    type: "User Login",
  });
  static userLogoutAction = () => ({
    type: "User Logout",
  });
  static setUserAction = (data) => ({
    type: "Set User",
    payload: data,
  });
}
