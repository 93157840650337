import {
    Link,
    Navbar,
    NavLeft,
    NavTitle,
    Page,
    f7,
    theme,
    Subnavbar,
    Searchbar,
    NavRight,
    Button,
  } from "framework7-react";
  import React, { Component } from "react";
  import { UserApiRepository } from "../services/user/UserApiRepository";
  import { connect } from "react-redux";
  import { auth } from "../services/firebase";
  import firebase from "firebase";
  import { RoomApiRepository } from "../services/room/RoomApiRepository";
  import AddRoomPopup from "../components/AddRoomPopup";
  import RoomActions from "../components/RoomActions";
  import RoomList from "../components/RoomList";
  
  class UserPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        rooms: props.rooms,
        vlData: {
          items: [],
        },
        addRoomPopupOpen: false,
        selectedRoomId: "",
        tokenData:"",
      };
    }
  

    componentDidMount = async () => {
      const app = f7;
      auth().onAuthStateChanged(async (user) => {
        //app.dialog.preloader("Fetching User");
        if (user) {
          // User is Logged In
          await this.props.setUser(user);
        //  app.dialog.close();
        } else {
          // User is Logged Out
          await this.props.setUser(null);
          
         // app.dialog.close();
          this.props.f7router.navigate('/');
        }
      });

      

      // Fetch Rooms and initialize virtual list
      await this.props.fetchRooms();
      this.setState(
        {
          rooms: this.props.rooms,
        },
        () => {
          const virtualList = f7.virtualList.get(".virtual-list");
          virtualList.replaceAllItems(this.state.rooms);
        }
      );
    };
  

    // User Login
    loginUser = async (provider) => {
      await this.props.loginUser(provider);
    };
  
    // User Logout
    logoutUser = async () => {
      console.log("user Logout Initate")
      await this.props.logoutUser();
     // this.props.f7router.navigate('/');

    };
  
    // Search Function for Room Virtual List
    searchAll = (query, searchItems) => {
      const found = [];
      for (let i = 0; i < searchItems.length; i += 1) {
        if (
          searchItems[i].roomName.toLowerCase().indexOf(query.toLowerCase()) >=
            0 ||
          searchItems[i].roomDescription
            .toLowerCase()
            .indexOf(query.toLowerCase()) >= 0 ||
          query.trim() === ""
        )
          found.push(i);
      }
      return found; // return array with matched indexes
    };
  
    // Room Virtual List renderExternal
    renderExternal = (vl, newData) => {
      this.setState({
        vlData: { ...newData },
      });
    };
  
    // Add New Room
    addRoom = async (e) => {
      const { user } = this.props;
      const app = f7;
      // Prevent Form Reload on Submit
      e.preventDefault();
      app.dialog.preloader("Adding New Room");
      try {
        // Get Form Data
        const formData = app.form.convertToData("#add-room-form");

///my edit


 await fetch(`https://dev2.awishcar.com:3000/access_token?channelName=${formData.roomName}`)
  .then(response => response.json())
  .then((data) => {
   this.setState({tokenData:data.token});
  })

////

        // Clear Form
        app.form.fillFromData("#add-room-form", {
          roomName: "",
          roomDescription: "",
        });
        // Add Room
        await this.props.addRoom({
          
          ...formData,
          createdBy: user.id,
          createdDate: firebase.firestore.Timestamp.fromDate(new Date()),
          //// my update
         cretedToken: this.state.tokenData,
          ////
          
        });
        // Refetch Updated Rooms List
        await this.props.fetchRooms();
        // Set Updated Rooms data to state and reset virtual list
        this.setState(
          {
            rooms: this.props.rooms,
          },
          () => {
            const virtualList = f7.virtualList.get(".virtual-list");
            virtualList.replaceAllItems(this.state.rooms);
          }
        );
        // Close Popup
        this.setState({ addRoomPopupOpen: false });
        app.dialog.close();
      } catch (error) {
        app.dialog.close();
      }
    };
  
    // Join Room
    joinRoom = async () => {
      const roomDetails = await this.props.fetchRoom(this.state.selectedRoomId);
    //  console.log('userID: ',this.state.rooms[0].id, ' userToken: ',this.state.rooms[0].cretedToken);
    
      this.props.f7router.navigate(`/room/${roomDetails.id}`);
    };
  
    // Delete Room by Id
    deleteRoom = async () => {
      await this.props.deleteRoom(this.state.selectedRoomId);
      
      // TODO: Break out the following snippet into separate function to be called from add, delete and cdm
      // Refetch Updated Rooms List
      await this.props.fetchRooms();
      // Set Updated Rooms data to state and reset virtual list
      this.setState(
        {
          rooms: this.props.rooms,
        },
        () => {
          const virtualList = f7.virtualList.get(".virtual-list");
          virtualList.replaceAllItems(this.state.rooms);
        }
      );
    };
    
  
    render() {
      const { rooms, vlData } = this.state;
      const { user, joinedFlag, joinedRoom } = this.props;
      return (
        <Page>
          <Navbar transparent style={{marginTop:10}}>
          {user &&( 
            <>
            <NavLeft >
            <Link href='/profile/'>
          <div>
            <img  src={user.avatar}  height={'50%'} width={'50%'} style={{borderRadius:'50%',overflow:'hidden'}}/>
        </div> </Link>
            </NavLeft>
            <NavTitle>{user.name}</NavTitle>
            </>
            )}
            <NavRight>
              <Link
                iconOnly
                iconF7={"plus_circle"}
                onClick={() => this.setState({ addRoomPopupOpen: true })}
              />
              {user && (
                <Button outline small text="Logout" onClick={this.logoutUser} />
              )}
            </NavRight>
            <Subnavbar inner={false}>
              <Searchbar
                searchContainer=".virtual-list"
                searchItem="li"
                searchIn=".item-title"
                disableButton={!theme.aurora}
              />
            </Subnavbar>
          </Navbar>
  
          {/* Room List */}
          <RoomList
            rooms={rooms}
            vlData={vlData}
            searchAll={this.searchAll}
            renderExternal={this.renderExternal}
            setState={(state) => this.setState(state)}
            user={user}
            joinedFlag={joinedFlag}
            roomName={joinedRoom ? joinedRoom.roomName : null}
            joinRoom={this.joinRoom}
          />
          
          {/* Add New Room Popup */}
          <AddRoomPopup
            addRoomPopupOpen={this.state.addRoomPopupOpen}
            setState={(state) => this.setState(state)}
            addRoom={this.addRoom}
          />
  
          {/* Room Actions */}
          <RoomActions joinRoom={this.joinRoom} deleteRoom={this.deleteRoom} />
        </Page>
      );
    }
  }
  
  const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    rooms: state.roomReducer.rooms,
    joinedFlag: state.roomReducer.joinedFlag,
    joinedRoom: state.roomReducer.joinedRoom,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    setUser: (data) => dispatch(UserApiRepository.setUser(data)),
    loginUser: (provider) => dispatch(UserApiRepository.loginUser(provider)),
    logoutUser: () => dispatch(UserApiRepository.logoutUser()),
    fetchRooms: () => dispatch(RoomApiRepository.fetchRooms()),
    addRoom: (data) => dispatch(RoomApiRepository.addRoom(data)),
    fetchRoom: (id) => dispatch(RoomApiRepository.fetchRoom(id)),
    deleteRoom: (id) => dispatch(RoomApiRepository.deleteRoom(id)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
  