import {
    Navbar,
    Page,
    Block,
    BlockTitle,
    NavLeft,
    Link
  } from "framework7-react";
  import React, { Component } from "react";
  import { connect } from "react-redux";

  class UserProfile extends Component {
    constructor(props) {
      super(props);
    }
  
  
    render() {
   
      const { user } = this.props;
      return (
        <Page style={{backgroundColor:'#cccccc'}}>
      <Navbar>
        <NavLeft>
        <Link icononly iconF7={"arrow_left"} href='/user/'></Link>
        </NavLeft>
      </Navbar>
         <div style={{marginTop:'10%'}}>
         <img  src={user.avatar}  height={'40%'} style={{width:'40%',marginLeft:'30%',marginLeft:'30%',borderRadius:'50%',overflow:'hidden'}}/>
        <div style={{textAlign:'center',marginTop:'5%'}}>
             <h1>{user.name}</h1>
             </div>
         </div>
        
         <BlockTitle>User ID :- </BlockTitle>
    <Block strong inset>
      <p>{user.id}</p>
    </Block>
    <BlockTitle>User Email :- </BlockTitle>
    <Block strong inset>
      <p>{user.email}</p>
    </Block>
    <BlockTitle>User Role:- </BlockTitle>
    <Block strong inset>
      <p>{user.role}</p>
    </Block>
        </Page>
      );
    }
  }
  
  const mapStateToProps = (state) => ({
    user: state.userReducer.user,
  });
  
  const mapDispatchToProps = (dispatch) => ({
   // setUser: (data) => dispatch(UserApiRepository.setUser(data)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
  