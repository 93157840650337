const initialState = {
  rooms: [],
  joinedFlag: false,
  joinedRoom: null,
  joinedUser: null,
  joinedModerators: [],
  joinedSpeakers: [],
  joinedAudience: [],
  agoraInstance: null,
};

export const RoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Fetch Rooms": {
      return { ...state, rooms: action.payload };
    }
    case "Set Joined Moderators": {
      return { ...state, joinedModerators: action.payload };
    }
    case "Set Joined Speakers": {
      return { ...state, joinedSpeakers: action.payload };
    }
    case "Set Joined Audience": {
      return { ...state, joinedAudience: action.payload };
    }
    case "Set Joined Room": {
      return { ...state, joinedRoom: action.payload };
    }
    case "Set Joined User": {
      return { ...state, joinedUser: action.payload, joinedFlag: true };
    }
    case "Set Agora Instance": {
      return { ...state, agoraInstance: action.payload };
    }
    case "Leave Room": {
      return {
        ...state,
        joinedFlag: false,
        joinedRoom: null,
        joinedUser: null,
        joinedModerators: [],
        joinedSpeakers: [],
        joinedAudience: [],
        agoraInstance: null,
      };
    }
    default: {
      return state;
    }
  }
};
