import { Card, Icon, Link } from "framework7-react";
import React from "react";
import "../css/MemberCard.css";

export default function MemberCard({ title, data, memberClickHandler }) {
  return (
    <Card className={"member-card elevation-0"}>
      <div className={"member-card-div"}>
        <div className={"member-card-header"}>
          <p className={"member-card-title"}>{title}</p>
        </div>
        <div className={"member-card-content"}>
          {data.length > 0 &&
            data.map((val, ind) => (
              <Link
                noLinkClass
                className={"member-div"}
                key={ind}
                onClick={() => memberClickHandler(val)}
                popoverOpen=".member-popover"
              >
                <div className={"member-avatar-div "}>
                  <img
                    src={val.avatar}
                    className={"member-avatar elevation-2"}
                  />
                  {val.role !== 3 && (
                    <Icon
                      f7={val.muteFlag ? "mic_slash_fill" : "mic_fill"}
                      className={"mic-icon elevation-2"}
                    />
                  )}
                  {val.role == 3 && val.raiseHandFlag && (
                    <Icon
                      f7={
                        val.raiseHandFlag ? "hand_raised" : "hand_raised_slash"
                      }
                      className={"mic-icon elevation-2"}
                    />
                  )}
                </div>
                <div className={"member-name-div"}>
                  <p className={"member-name"}>
                    {val.name ? val.name.split(" ")[0] : "User"}
                  </p>
                </div>
              </Link>
            ))}
          {data.length == 0 && <h4>{`No ${title} Joined`}</h4>}
        </div>
        <div className={"member-card-footer"}></div>
      </div>
    </Card>
  );
}
