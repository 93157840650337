/*

import AgoraRTC from "agora-rtc-sdk";

export default class Agora {
  config = {
    APP_ID: null,
    TOKEN: null,
    CHANNEL_NAME: null,
    MODE: null,
    CODEC: null,
    USER_ID: null,
    _CLIENT: null,
    _LOCALSTREAM: null,
    _REMOTESTREAMS: [],
  };

  constructor(config) {
    this.config.APP_ID = config.APP_ID;
    this.config.TOKEN = config.TOKEN;
    this.config.CHANNEL_NAME = config.CHANNEL_NAME;
    this.config.MODE = config.MODE;
    this.config.CODEC = config.CODEC;
    this.config.USER_ID = config.USER_ID;
  }

  getConfig() {
    console.log('config Data ',this.config);
  }

  handleError = (err) => {
    console.log("Error: ", err);
  };

  remoteContainer = document.getElementById("remote-container");

  addVideoStream(elementId) {
    let streamDiv = document.createElement("div");
    streamDiv.id = elementId;
    streamDiv.style.transform = "rotateY(180deg)";
    this.remoteContainer.appendChild(streamDiv);
  }

  removeVideoStream(elementId) {
    let remoteDiv = document.getElementById(elementId);
    if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv);
  }

  // AGORA CORE METHODS ------------------------------------------ [START]

  createClient() {
    return new Promise((resolve, reject) => {
      const { MODE, CODEC } = this.config;
      let client = AgoraRTC.createClient({
        mode: MODE,
        codec: CODEC,
      });
      this.config._CLIENT = client;
      this.handleEvents();
      resolve(client);
    });
  }

  initClient() {
    return new Promise((resolve, reject) => {
      const { APP_ID, _CLIENT } = this.config;
      _CLIENT.init(
        APP_ID,
        () => {
          // Client Init Success
          console.info("INIT CLIENT SUCCESS");
          resolve(_CLIENT);
        },
        (error) => {
          // Client Init Failed
          console.err("INIT CLIENT FAILED");
          reject(error);
        }
      );
    });
  }

  join() {
    return new Promise((resolve, reject) => {
      const { APP_ID, TOKEN, CHANNEL_NAME, USER_ID, _CLIENT } = this.config;
      // Join a channel
      _CLIENT.join(
        TOKEN,
        CHANNEL_NAME,
        USER_ID,
        (uid) => {
          // Create a local stream
          let localStream = AgoraRTC.createStream({
            audio: true,
            video: false,
          });
          this.config._LOCALSTREAM = localStream;
          resolve(localStream);
        },
        this.handleError
      );
    });
  }

  initLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _LOCALSTREAM } = this.config;
      // Initialize the local stream
      _LOCALSTREAM.init(() => {
        // Play the local stream
        _LOCALSTREAM.play("me");
        resolve(true);
      }, this.handleError);
    });
  }

  publishLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Publish the local stream
      _CLIENT.publish(_LOCALSTREAM, this.handleError);
      console.log("CONFIG", this.config);
      resolve(true);
    });
  }

  unpublishLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Unpublish the local stream
      _CLIENT.unpublish(_LOCALSTREAM, this.handleError);
      resolve(true);
    });
  }

  muteLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Mute audio of the local stream
      _LOCALSTREAM.muteAudio();
      resolve(true);
    });
  }

  unmuteLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Unmute audio of the local stream
      _LOCALSTREAM.unmuteAudio();
      resolve(true);
    });
  }

  leave() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM, _REMOTESTREAMS } = this.config;
      // Unmute audio of the local stream
      _CLIENT.leave(
        () => {
          if (_LOCALSTREAM.isPlaying()) {
            _LOCALSTREAM.stop();
          }
          _LOCALSTREAM.close();
          for (let i = 0; i < _REMOTESTREAMS.length; i++) {
            var stream = _REMOTESTREAMS.shift();
            let streamId = String(stream.getId());
            if (stream.isPlaying()) {
              stream.stop();
            }
            this.removeVideoStream(streamId);
          }
          this.config._LOCALSTREAM = null;
          this.config._REMOTESTREAMS = [];
          this.config._CLIENT = null;
          console.log("client leaves channel success");
          console.log("CONFIG", this.config);
          resolve(true);
        },
        (err) => {
          reject(true);
        }
      );
    });
  }

  handleEvents = () => {
    const self = this;
    const { _CLIENT, _REMOTESTREAMS, USER_ID } = this.config;
    // Occurs when an error message is reported and requires error handling.
    _CLIENT.on("error", (err) => {
      console.log(err);
    });

    // Occurs when the peer user leaves the channel; for example, the peer user calls Client.leave.
    _CLIENT.on("peer-leave", function (evt) {
      var id = evt.uid;
      console.log("id", evt);
      let streams = _REMOTESTREAMS.filter((e) => id !== e.getId());
      let peerStream = _REMOTESTREAMS.find((e) => id === e.getId());
      if (peerStream && peerStream.isPlaying()) {
        peerStream.stop();
      }
      self.config._REMOTESTREAMS = streams;
      if (id !== USER_ID) {
        // removeView
        self.removeVideoStream(id);
      }
      //   Toast.notice("peer leave")
      console.log("peer-leave", id);
    });

    // Subscribe to the remote stream when it is published
    _CLIENT.on("stream-added", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      console.log("stream-added uid: " + streamId);
      if (streamId !== USER_ID) {
        _CLIENT.subscribe(stream, this.handleError);
      }
      console.log("stream-added remote-uid: ", streamId);
    });

    // Occurs when the local stream is published.
    _CLIENT.on("stream-published", function (evt) {
      //   Toast.notice("stream published success")
      console.log("stream-published");
    });

    // Play the remote stream when it is subsribed
    _CLIENT.on("stream-subscribed", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      _REMOTESTREAMS.push(stream);
      self.addVideoStream(streamId);
      stream.play(streamId);
    });

    // Remove the corresponding view when a remote user unpublishes.
    _CLIENT.on("stream-removed", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      console.log("stream-removed uid: " + streamId);
      if (stream.isPlaying()) {
        stream.stop();
      }
      stream.close();
      _REMOTESTREAMS = _REMOTESTREAMS.filter(function (stream) {
        return stream.getId() !== id;
      });
      self.removeVideoStream(streamId);
      console.log("stream-removed remote-uid: ", streamId);
    });

    // Remove the corresponding view when a remote user leaves the channel.
    _CLIENT.on("peer-leave", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      stream.close();
      self.removeVideoStream(streamId);
    });
  };

  // AGORA CORE METHODS ------------------------------------------ [END]
}

*/


import AgoraRTC from "agora-rtc-sdk";

export default class Agora {
  config = {
    APP_ID: null,
    TOKEN: null,
    CHANNEL_NAME: null,
    MODE: null,
    CODEC: null,
    USER_ID: null,
    _CLIENT: null,
    _LOCALSTREAM: null,
    _REMOTESTREAMS: [],
  };

  constructor(config) {
    this.config.APP_ID = config.APP_ID;
    this.config.TOKEN = config.TOKEN;
    this.config.CHANNEL_NAME = config.CHANNEL_NAME;
    this.config.MODE = config.MODE;
    this.config.CODEC = config.CODEC;
    this.config.USER_ID = config.USER_ID;


}


  
  
  
  
  getConfig() {
    
    console.log('config Data ',this.config);
  }

  handleError = (err) => {
    console.log("Error: ", err);
  };

  remoteContainer = document.getElementById("remote-container");

  addVideoStream(elementId) {
    let streamDiv = document.createElement("div");
    streamDiv.id = elementId;
    streamDiv.style.transform = "rotateY(180deg)";
    this.remoteContainer.appendChild(streamDiv);
  }

  removeVideoStream(elementId) {
    let remoteDiv = document.getElementById(elementId);
    if (remoteDiv) remoteDiv.parentNode.removeChild(remoteDiv);
  }

  // AGORA CORE METHODS ------------------------------------------ [START]

  createClient() {
    return new Promise((resolve, reject) => {
      const { MODE, CODEC } = this.config;
      let client = AgoraRTC.createClient({
        mode: MODE,
        codec: CODEC,
      });
      this.config._CLIENT = client;
      this.handleEvents();
      resolve(client);
    });
  }

  initClient() {
    return new Promise((resolve, reject) => {
      const { APP_ID, _CLIENT } = this.config;
      _CLIENT.init(
        APP_ID,
        () => {
          // Client Init Success
          console.info("INIT CLIENT SUCCESS");
          resolve(_CLIENT);
        },
        (error) => {
          // Client Init Failed
          console.err("INIT CLIENT FAILED");
          reject(error);
        }
      );
    });
  }

  join() {
    return new Promise((resolve, reject) => {
      const { APP_ID, TOKEN, CHANNEL_NAME, USER_ID, _CLIENT } = this.config;
      // Join a channel
      _CLIENT.join(
        TOKEN,
        CHANNEL_NAME,
        USER_ID,
        (uid) => {
          // Create a local stream
          let localStream = AgoraRTC.createStream({
            audio: true,
            video: false,
          });
          this.config._LOCALSTREAM = localStream;
          resolve(localStream);
        },
        this.handleError
      );
    });
  }

  initLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _LOCALSTREAM } = this.config;
      // Initialize the local stream
      _LOCALSTREAM.init(() => {
        // Play the local stream
        _LOCALSTREAM.play("me");
        resolve(true);
      }, this.handleError);
    });
  }

  publishLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Publish the local stream
      _CLIENT.publish(_LOCALSTREAM, this.handleError);
      console.log("CONFIG", this.config);
      resolve(true);
    });
  }

  unpublishLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Unpublish the local stream
      _CLIENT.unpublish(_LOCALSTREAM, this.handleError);
      resolve(true);
    });
  }

  muteLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Mute audio of the local stream
      _LOCALSTREAM.muteAudio();
      resolve(true);
    });
  }

  unmuteLocalSteam() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM } = this.config;
      // Unmute audio of the local stream
      _LOCALSTREAM.unmuteAudio();
      resolve(true);
    });
  }

  leave() {
    return new Promise((resolve, reject) => {
      const { _CLIENT, _LOCALSTREAM, _REMOTESTREAMS } = this.config;
      // Unmute audio of the local stream
      _CLIENT.leave(
        () => {
          if (_LOCALSTREAM.isPlaying()) {
            _LOCALSTREAM.stop();
          }
          _LOCALSTREAM.close();
          for (let i = 0; i < _REMOTESTREAMS.length; i++) {
            var stream = _REMOTESTREAMS.shift();
            let streamId = String(stream.getId());
            if (stream.isPlaying()) {
              stream.stop();
            }
            this.removeVideoStream(streamId);
          }
          this.config._LOCALSTREAM = null;
          this.config._REMOTESTREAMS = [];
          this.config._CLIENT = null;
          console.log("client leaves channel success");
          console.log("CONFIG", this.config);
          resolve(true);
        },
        (err) => {
          reject(true);
        }
      );
    });
  }

  handleEvents = () => {
    const self = this;
    const { _CLIENT, _REMOTESTREAMS, USER_ID } = this.config;
    // Occurs when an error message is reported and requires error handling.
    _CLIENT.on("error", (err) => {
      console.log(err);
    });

    // Occurs when the peer user leaves the channel; for example, the peer user calls Client.leave.
    _CLIENT.on("peer-leave", function (evt) {
      var id = evt.uid;
      console.log("id", evt);
      let streams = _REMOTESTREAMS.filter((e) => id !== e.getId());
      let peerStream = _REMOTESTREAMS.find((e) => id === e.getId());
      if (peerStream && peerStream.isPlaying()) {
        peerStream.stop();
      }
      self.config._REMOTESTREAMS = streams;
      if (id !== USER_ID) {
        // removeView
        self.removeVideoStream(id);
      }
      //   Toast.notice("peer leave")
      console.log("peer-leave", id);
    });

    // Subscribe to the remote stream when it is published
    _CLIENT.on("stream-added", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      console.log("stream-added uid: " + streamId);
      if (streamId !== USER_ID) {
        _CLIENT.subscribe(stream, this.handleError);
      }
      console.log("stream-added remote-uid: ", streamId);
    });

    // Occurs when the local stream is published.
    _CLIENT.on("stream-published", function (evt) {
      //   Toast.notice("stream published success")
      console.log("stream-published");
    });

    // Play the remote stream when it is subsribed
    _CLIENT.on("stream-subscribed", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      _REMOTESTREAMS.push(stream);
      self.addVideoStream(streamId);
      stream.play(streamId);
    });

    // Remove the corresponding view when a remote user unpublishes.
    _CLIENT.on("stream-removed", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      console.log("stream-removed uid: " + streamId);
      if (stream.isPlaying()) {
        stream.stop();
      }
      stream.close();
      _REMOTESTREAMS = _REMOTESTREAMS.filter(function (stream) {
        return stream.getId() !== id;
      });
      self.removeVideoStream(streamId);
      console.log("stream-removed remote-uid: ", streamId);
    });

    // Remove the corresponding view when a remote user leaves the channel.
    _CLIENT.on("peer-leave", function (evt) {
      let stream = evt.stream;
      let streamId = String(stream.getId());
      stream.close();
      self.removeVideoStream(streamId);
    });
  };

  // AGORA CORE METHODS ------------------------------------------ [END]
}

