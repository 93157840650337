export class RoomActions {
  static fetchRoomsAction = (data) => ({
    type: "Fetch Rooms",
    payload: data,
  });

  static setJoinedModeratorsAction = (data) => ({
    type: "Set Joined Moderators",
    payload: data,
  });

  static setJoinedSpeakersAction = (data) => ({
    type: "Set Joined Speakers",
    payload: data,
  });

  static setJoinedAudienceAction = (data) => ({
    type: "Set Joined Audience",
    payload: data,
  });

  static setJoinedRoomAction = (data) => ({
    type: "Set Joined Room",
    payload: data,
  });

  static setJoinedUserAction = (data) => ({
    type: "Set Joined User",
    payload: data,
  });

  static setAgoraInstanceAction = (data) => ({
    type: "Set Agora Instance",
    payload: data,
  });

  static leaveRoomAction = () => ({
    type: "Leave Room",
  });
}
