import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Popover,
} from "framework7-react";
import React from "react";

export default function MemberPopover({ selectedUser, changeUserType }) {
  return (
    <Popover className="member-popover">
      <Card className="demo-facebook-card">
        <CardHeader className="no-border">
          <div className="demo-facebook-avatar">
            <img src={selectedUser?.avatar} width="34" height="34" />
          </div>
          <div className="demo-facebook-name">{selectedUser?.name}</div>
        </CardHeader>
        <CardContent>
          <p>{`Email: ${selectedUser?.email}`}</p>
          <span>About:</span>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
        </CardContent>
        <CardFooter
          className="no-border"
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <Button
            text="Request Business Card"
            fill
            color="deeporange"
            small
            style={{ width: "100%", marginBottom: "5px" }}
            popoverClose=".member-popover"
          />
          {selectedUser && selectedUser.role !== 1 && (
            <Button
              text="Make Moderator"
              fill
              color="teal"
              small
              style={{ width: "100%", marginBottom: "5px" }}
              popoverClose=".member-popover"
              onClick={() => changeUserType(1)}
            />
          )}
          {selectedUser && selectedUser.role !== 2 && (
            <Button
              text="Make Speaker"
              fill
              color="deeppurple"
              small
              style={{ width: "100%", marginBottom: "5px" }}
              popoverClose=".member-popover"
              onClick={() => changeUserType(2)}
            />
          )}
          {selectedUser && selectedUser.role !== 3 && (
            <Button
              text="Make Audience"
              fill
              color="pink"
              small
              style={{ width: "100%" }}
              popoverClose=".member-popover"
              onClick={() => changeUserType(3)}
            />
          )}
        </CardFooter>
      </Card>
    </Popover>
  );
}
