import { List, ListItem, theme, f7 } from "framework7-react";
import React from "react";

export default function RoomList({
  rooms,
  vlData,
  searchAll,
  renderExternal,
  setState,
  user,
  joinedFlag,
  roomName,
  joinRoom
}) {
  return (
    <>
      <List className="searchbar-not-found">
        <ListItem title="Nothing found" />
      </List>
      <List
        className="searchbar-found"
        medialList
        virtualList
        virtualListParams={{
          items: rooms,
          searchAll: searchAll,
          renderExternal: renderExternal,
          height: theme.ios ? 63 : theme.md ? 73 : 77,
        }}
      >
        <ul>
          {vlData.items.map((item, index) => (
            <ListItem
              key={index}
              mediaItem
              link="#"
              title={item.roomName}
              subtitle={item.roomDescription}
              after={ joinedFlag && roomName == item.roomName ? "🔗 Connected" : ""}
              style={{ top: `${vlData.topPosition}px` }}
              virtualListIndex={rooms.indexOf(item)}
              onClick={() => {
                if (user) {
                  setState({ selectedRoomId: item.id });
                  if (joinedFlag && roomName == item.roomName) {
                    joinRoom()
                  }
                } else {
                  f7.dialog.alert("Kindly login to join the room.");
                }
              }}
              actionsOpen={user && !joinedFlag ? "#actions-two-groups" : ""}
            >
              <img
                src="https://cdn4.iconfinder.com/data/icons/google-i-o-2016/512/google_firebase-512.png"
                alt=""
                width={40}
                height={40}
                slot={"media"}
              />
            </ListItem>
          ))}
        </ul>
      </List>
    </>
  );
}
