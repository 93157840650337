import HomePage from "../pages/HomePage.jsx";
import NotFoundPage from "../pages/404.jsx";
import RoomPage from "../pages/RoomPage.jsx";
import WelcomePage from "../pages/Welcome.jsx";
import Signup from "../pages/Signup.jsx";
import UserPage from "../pages/UserPage.jsx";
import userProfile from "../pages/userProfile.jsx";

var routes = [
//  {
//    path:"/",
//    component: WelcomePage,
//  },
  {
    path: "/",
    component: Signup,
  },
  {
    path: "/user/",
    component: UserPage,
  },
  {
    path: "/profile/",
    component: userProfile,
  },
  {
    path: "/home/",
    component: HomePage,
  },
  {
    path: "/room/:roomId",
    component: RoomPage,
  }, 
  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

export default routes;
