import React from 'react';
export const  ApiTokenData=({rooms})=>{
  console.log(rooms[0].cretedToken);
  return (
    <h1>{rooms.cretedToken}</h1>
  )
}


export const DevEnvironment = {
  FIREBASE_API_KEY: "AIzaSyAWHmL4bUxvQHnB0rx6KDaFsKkwWMvgA2g",
  FIREBASE_AUTH_DOMAIN: "sanket-app-6aa42.firebaseapp.com",
  FIREBASE_PROJECT_ID: "sanket-app-6aa42",
  FIREBASE_STORAGE_BUCKET: "sanket-app-6aa42.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "713978998916",
  FIREBASE_APP_ID: "1:713978998916:web:11e6f40ebf6f83bf84d2d1",
  AGORA_APP_ID: "dad09d2250ce496d94ae7adeab30bd79",
 //AGORA_TOKEN:"006dad09d2250ce496d94ae7adeab30bd79IAC++oMsqFTI7h7jacuGbej3BWpoS4qsu23Bf878Qh3WJCu43uMAAAAAEADqPpSLs8/hYAEAAQBDjOBg",
  AGORA_MODE: "rtc",
  AGORA_CODEC: "h264",
};

// TOKEN_FOR_CHANNEL: Awishcar Test Room
// APP_SECRET: f940336a880144d9a296cd5ef26c30c7

